<template>
  <div class="authentication">
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>
<script>
export default {};
</script>
